//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    image: {
      type: Object,
      default: null
    },
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    class2: {
      type: String,
      default: ''
    }
  }
}
