//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModuleRABanner',
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  }
}
