//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'

export default {
  mixins: [global],
  props: {
    article: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      projectData
    }
  }
}
