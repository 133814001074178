//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'

export default {
  mixins: [global],
  props: {
    article: {
      type: Object,
      default: () => null
    },
    size: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    }
  },
  data () {
    let photo = null
    if (this.article) {
      if ((this.size === 'small') && this.article.photo_small) {
        photo = this.article.photo_small
        if (!photo.width) {
          photo.width = 256
        }
        if (!photo.height) {
          photo.height = 144
        }
      } else if ((this.size === 'large') && this.article.photo_large) {
        photo = this.article.photo_large
        if (!photo.width) {
          photo.width = 1200
        }
        if (!photo.height) {
          photo.height = 572
        }
      } else if (this.article.photo) {
        photo = this.article.photo
        photo.size = 'medium'
        if (!photo.width) {
          photo.width = 419
        }
        if (!photo.height) {
          photo.height = 200
        }
      }

      if (photo) {
        photo.size = (['small', 'large'].includes(this.size) && this.size) || 'medium'
      }
    }
    return {
      photo
    }
  }
}
