//
//
//
//
//
//
//
//
//

export default {
  props: {
    active: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: ''
    },
    textId: {
      type: String,
      default: ''
    },
    textName: {
      type: String,
      default: ''
    },
    fId: {
      type: String,
      default: ''
    },
    filterUrl: {
      type: String,
      default: ''
    }
  }
}
