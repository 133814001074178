//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
export default {
  props: {
    submitForm: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    buttonText: {
      type: String,
      default: ''
    },
    hasName: {
      type: Boolean,
      default: true
    }
  },
  async asyncData ({ app }) {

  },
  data () {
    return {
      modo: 'registro',
      projectData,
      valid: false,
      showPassword: false,
      userInfo: {
        email: '',
        password: '',
        p: this.$config.id
      },
      signinInfo: {
        name: '',
        validName: true,
        lastName: '',
        username: '',
        validUsername: true,
        email: '',
        validEmail: true,
        errorEmail: '',
        password: '',
        validPassword: true,
        sex: '',
        source: 'Gembira',
        rememberPassword: false,
        privacy: false,
        validPrivacy: true,
        pisada: '',
        commercial_communications: false
      },
      imageData: null,
      selected: null,
      options: [
        // Cargar todas las provincias
        { value: null, text: 'Provincia' },
        { value: 'alaba', text: 'Álaba' },
        { value: 'Albacete', text: 'Albacete' },
        { value: 'bizkaia', text: 'Bizkaia' },
        { value: 'gipuzkoa', text: 'Gipuzkoa' },
        { value: 'alicante', text: 'Alicante' }
      ],
      paso: 1,
      error: '',
      access_token: '',
      refresh_token: '',
      sexos: [
        { value: '', text: `${this.$t('register.sex')}`, disabled: true },
        { value: 'man', text: `${this.$t('register.man')}` },
        { value: 'women', text: `${this.$t('register.women')}` }
      ],
      pisadas: [
        { value: '', text: `${this.$t('UserRegisterForm.tread_type')}`, disabled: true },
        { value: 'nose', text: `${this.$t('UserRegisterForm.dont_know')}` },
        { value: 'pronadora', text: `${this.$t('UserRegisterForm.pronator')}` },
        { value: 'neutra', text: `${this.$t('UserRegisterForm.neutral')}` },
        { value: 'supinadora', text: `${this.$t('UserRegisterForm.supinator')}` }
      ],
      // Imagenes
      runnearounded: { src: require('@/assets/img/logo-runnea-rounded.svg'), class: 'd-none d-lg-flex rounded-logo', alt: 'Runnea logo comunidad' }
    }
  },
  methods: {
    chooseImage () {
      this.$refs.fileInput.$refs.input.click()
    },
    onSelectFile () {
      const input = this.$refs.fileInput.$refs.input
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
    },
    resetForm () {
      this.signinInfo.email = ''
      this.signinInfo.name = ''
      this.signinInfo.sex = ''
      this.signinInfo.source = 'Gembira'
      this.signinInfo.password = ''
      this.signinInfo.pisada = ''
    },
    async register () {
      this.reset = true
      const bodyFormData = new FormData()
      if (this.isFormValid()) {
        bodyFormData.append('_p', this.$config.id)
        bodyFormData.append('username', this.signinInfo.username)
        bodyFormData.append('email', this.signinInfo.email)
        bodyFormData.append('name', this.signinInfo.name + ' ' + this.signinInfo.lastName)
        bodyFormData.append('sex', this.signinInfo.sex)
        bodyFormData.append('pisada', this.signinInfo.pisada)
        bodyFormData.append('source', this.signinInfo.source)
        bodyFormData.append('password', this.signinInfo.password)
        if (this.signinInfo.validCommunications) bodyFormData.append('service', 'newsletter')
        try {
          await this.$axios.post(this.$config.api + '/app/v1/users/signin', bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'No Auth'
            }
          }).then((resp) => {
            if (resp.status === 200) {
              this.access_token = resp.data.access_token
              this.refresh_token = resp.data.refresh_token
              this.loginUser()
              this.resetForm()
              this.error = this.$t('register.successfully_registered')
              this.paso = 2
              // TODO: [MP2020-1031] Hay que hacer algo cuando el usuario se registra bien.
              // Resultado si todo va bien
            } else {
              // TODO: [MP2020-1032] Gestión correcta de errores de registro (pensar y establecer control)
              this.error = resp.statusText
            }
            switch (resp.status) {
              case 491: this.error = this.$t('register.user_not_found'); break
              case 490: this.error = this.$t('register.error_recovering'); break
              case 481: this.error = this.$t('register.email_exists'); break
              case 482: this.error = this.$t('register.email_not_found'); break
            }
          })
        } catch (error) {
          this.userInfo.message = this.$t('register.not_exist_email')
        }
      } else {
        this.error = this.$t('register.missing_fields')
      }
    },
    loginUser () {
      const bodyFormData = new FormData()
      bodyFormData.append('_p', this.$config.id)
      bodyFormData.append('email', this.signinInfo.email)
      bodyFormData.append('password', this.signinInfo.password)
      try {
        this.$auth.loginWith('local', { data: bodyFormData }).then((resp) => {
          this.$auth.setRefreshToken('local', resp.data.refresh_token)
          if (localStorage.getItem('auth_redirect')) {
            this.$router.push({ path: localStorage.getItem('auth_redirect') })
          }
        })
      } catch (err) {
      }
    },
    isValidName (event) {
      if (this.signinInfo.name === '') {
        this.signinInfo.validName = false
      } else {
        this.signinInfo.validName = true
      }
    },
    isValidUsername (event) {
      if (this.signinInfo.username === '') {
        this.signinInfo.validUsername = false
      } else {
        this.signinInfo.validUsername = true
      }
    },
    isValidEmail (event) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.signinInfo.errorEmail = ''
      if (this.signinInfo.email === '') {
        this.signinInfo.validEmail = false
        this.signinInfo.errorEmail += this.$t('UserRegisterForm.required') + ' '
      } else {
        this.signinInfo.validEmail = true
      }
      if (!re.test(this.signinInfo.email)) {
        this.signinInfo.validEmail = false
        this.signinInfo.errorEmail += this.$t('register.incorrect_format') + ' '
      } else {
        this.signinInfo.validEmail = true
      }
    },
    isValidPassword (event) {
      if (this.signinInfo.password === '') {
        this.signinInfo.validPassword = false
      } else {
        this.signinInfo.validPassword = true
      }
    },
    isValidPrivacy (event) {
      this.signinInfo.validPrivacy = this.signinInfo.privacy
    },
    isValidCommunications (event) {
      this.signinInfo.validCommunications = this.signinInfo.commercial_communications
    },
    isFormValid () {
      this.isValidName(null)
      this.isValidUsername(null)
      this.isValidEmail(null)
      this.isValidPassword(null)
      this.isValidPrivacy(null)
      if (
        this.signinInfo.privacy &&
        this.signinInfo.validName &&
        this.signinInfo.validUsername &&
        this.signinInfo.validEmail &&
        this.signinInfo.validPassword
      ) {
        return true
      } else {
        return false
      }
    },
    changeLogin () {
      this.modo = 'login'
    },
    changeSingin () {
      this.modo = 'registro'
    }
  }
}
