//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    brandName: {
      type: String,
      default: ''
    },
    brandFeaturedProducts: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  }
}
