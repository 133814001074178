//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import Vue from 'vue'
import project from '~/static/config/project.json'
import global from '~/mixins/global.js'
Vue.config.silent = true
export default {
  components: {
    VueTypeaheadBootstrap
  },
  mixins: [global],
  props: {
    filters: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      iconSearch: require('~/assets/img/icons/icon-search.svg'),
      browserFilters: {},
      races: [],
      browserQry: '',
      RacesConfig: {},
      // Imagenes
      down: { src: require('@/assets/img/icons/icon-chevron-down-grey.svg'), alt: 'Close', width: 12, heigth: 7 },
      up: { src: require('@/assets/img/icons/icon-chevron-up-primary.svg'), alt: 'Open', width: 12, heigth: 7 }
    }
  },
  beforeMount () {
    this.RacesConfigFilters = project.contents.races.all.filters
  },
  methods: {
    findItem (race) {
      const pathMatches = race.path.match(/^\/json\/races\/(.+?)index.json$/)
      const url = `/${project.contents.races.all.paths.home}/${pathMatches[1]}`
      this.$router.push(url)
    },
    loadRaces () {
      if (!this.$store.state.productsLoaded) {
        this.$store.dispatch('loadRaces').then(() => {
          this.races = this.$store.state.races
        })
      } else {
        this.races = this.$store.state.races
      }
    },
    browseRaces (e) {
      const qryFilters = {}
      this.RacesConfigFilters.forEach((f) => {
        if (this.browserFilters[f.id]) {
          f.values.forEach((v) => {
            if (this.browserFilters[f.id].id === v.id) {
              qryFilters[f.id] = v.id_proyecto
            }
          })
        } else {
          qryFilters[f.id] = ''
        }
      })
      let textoUrl = ''
      // Distancia
      if (qryFilters.distance !== '') {
        textoUrl += `/${qryFilters.distance}`
      }
      // Superficie
      if (qryFilters.surface !== '') {
        textoUrl += `/${qryFilters.surface}`
      }
      // Localización
      if (qryFilters.province !== '' && qryFilters.country !== '') {
        textoUrl += `/${qryFilters.province}`
      } else if (qryFilters.province !== '') {
        textoUrl += `/${qryFilters.province}`
      } else if (qryFilters.country !== '') {
        textoUrl += `/${qryFilters.country}`
      }
      // Fecha
      if (qryFilters.year !== '' && qryFilters.month !== '') {
        textoUrl += `/${qryFilters.month}-${qryFilters.year}`
      } else if (qryFilters.year !== '') {
        textoUrl += `/${this.RacesConfigFilters.find(f => f.id === 'year').name}-${qryFilters.year}`
      } else if (qryFilters.month !== '') {
        textoUrl += `/${qryFilters.month}-${new Date().getFullYear()}`
      }

      // Texto
      this.$router.push(`${project.contents.races.all.path.home}/${project.contents.races.all.path.calendar}/${textoUrl}/${(this.browserQry !== '') ? '?q=' + this.browserQry : ''}`)
    }
  }
}
