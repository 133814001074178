//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import validations from 'validation-utils'
import projectData from '~/static/config/project.json'
export default {
  props: {
    submitForm: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    buttonText: {
      type: String,
      default: ''
    },
    hasName: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      valid: false,
      showPassword: false,
      userInfo: {
        email: '',
        password: '',
        _p: this.$config.id
      },
      rememberPassword: false,
      error: '',
      projectData
    }
  },
  methods: {
    loginUser () {
      const bodyFormData = new FormData()
      bodyFormData.append('_p', this.userInfo._p)
      bodyFormData.append('email', this.userInfo.email)
      bodyFormData.append('password', this.userInfo.password)
      this.error = ''
      try {
        this.$auth.loginWith('local', { data: bodyFormData }).then((resp) => {
          if (resp.status === 200) {
            this.$auth.setRefreshToken('local', resp.data.refresh_token)
            if (localStorage.getItem('auth_redirect')) {
              this.$router.push({ path: localStorage.getItem('auth_redirect') })
            } else {
              this.$bvModal.hide('register-modal')
            }
          } else {
            this.error = resp.statusText
          }
        }).catch(() => {
          // console.log(error)
          this.error = this.$t('UserAuthForm.wrong_password')
        })
      } catch (err) {
        this.userInfo.message = this.$t('UserAuthForm.no_email')
        // console.log(err)
      }
    }
  }
}
