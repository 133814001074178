//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'

export default {
  mixins: [global],
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    content: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    let articles = []
    if (this.content.info && (this.content.info.length > 0)) {
      articles = articles.concat(this.content.info)
    }
    if (this.data && (this.data.length > 0)) {
      articles = articles.concat(this.data)
    }
    let footer = false
    if (this.generic.footer && (this.generic.footer.length > 0)) {
      footer = this.generic.footer[0]
    }
    return {
      projectData,
      articles,
      footer
    }
  }
}
