//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
import global from '~/mixins/global.js'
export default {
  name: 'ModuleAlert',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  mixins: [global],

  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      titles: [],
      links: [],
      actualTitle: {},
      startIndex: 0,
      canBeShowed: false

    }
  }
}
