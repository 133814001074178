//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    sections: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      paso: 1,
      newQuestion: {
        section: {},
        text: '',
        suscription: true
      },
      successMsg: '',
      // Imagenes
      close: { alt: 'close', src: require('@/assets/img/icons/icon-close-white.svg'), width: 12, heigth: 7 },
      up: { alt: 'open', src: require('@/assets/img/icons/icon-chevron-up-primary.svg'), width: 12, heigth: 7 },
      left: { alt: 'left', src: require('@/assets/img/icons/icon-arrow-back.svg'), width: 12, heigth: 7 }
    }
  },
  methods: {
    hideModal () {
      this.$refs['inquiry-modal'].hide()
    },
    async submitQuestion () {
      const bodyFormData = new FormData()
      bodyFormData.append('section_id', this.newQuestion.section.content_id)
      bodyFormData.append('suscription', this.newQuestion.suscription ? 1 : 0)
      bodyFormData.append('data[text] ', this.newQuestion.text)
      try {
        await this.$axios.post(this.$config.api + '/app/v1/users/chats', bodyFormData, {
          headers: {
            Authorization: this.$auth.strategy.token.get()
          }
        }).then((resp) => {
          if (resp.status === 200) {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newInquiryButton.thanks')}`
          } else {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newInquiryButton.try_again')}`
          }
        })
      } catch (error) {
        this.hideModal()
        this.$refs['succes-modal'].show()
        this.successMsg = `${this.$t('newInquiryButton.try_again')}`
      }
    }
  }
}
