//
//
//
//
//
//
//

import Vue from 'vue'
export default {
  data () {
    return { isFBReady: false }
  },
  mounted () {
    this.isFBReady = Vue.FB !== undefined
    window.addEventListener('fb-sdk-ready', this.onFBReady)
  },
  beforeDestroy () {
    window.removeEventListener('fb-sdk-ready', this.onFBReady)
  },
  methods: {
    onFBReady () {
      this.isFBReady = true
    }
  }
}
