//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
import global from '~/mixins/global.js'
export default {
  name: 'ModulePopup',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  mixins: [global],
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    }
  },
  mounted () {
    sessionStorage.getItem('already_see_popup')
    if (sessionStorage.getItem('already_see_popup') == null) {
      this.$bvModal.show('popup')
      sessionStorage.setItem('already_see_popup', 'true')
    }
  },
  methods: {
    goTo (link) {
      window.open(link, '_blank')
    }
  }
}
