//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleProductFeaturedPrices',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  components: {
    PriceCard: () => import('~/components/PriceCard.vue'),
    AdvertisingProductsCard: () => import('~/components/AdvertisingProductsCard.vue')
  },
  props: {
    prices: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    c: {
      type: Number,
      default: 5
    },
    title: {
      type: String,
      default: ''
    }
  }
}
