//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      productsData: this.data
    }
  }
}
