var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MdlRaStr container my-4 pt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[(_vm.data.title)?_c('h2',{staticClass:"subTitle with-marker"},[_vm._v("\n        "+_vm._s(_vm.data.title)+"\n      ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-carousel',{attrs:{"id":"carouselStories","inteval":5000,"indicators":""}},_vm._l((_vm.data.list),function(item,ind){return _c('b-carousel-slide',{key:ind},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 text-center text-md-left"},[(item.image && item.image.src)?_c('div',{staticClass:"d-md-flex align-content-md-center flex-wrap h-100"},[_c('img',{staticClass:"mw-100",attrs:{"src":item.image.src,"width":item.image.width,"height":item.image.height}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-8 text-center text-md-left"},[(
                  item.title &&
                    item.title.type &&
                    item.title.type == 'runneante' &&
                    item.title.text)?_c('h3',{staticClass:"runneante"},[_vm._v("\n                "+_vm._s(item.title.text)+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((item.body),function(itemBody,indBofy){return [(itemBody.type && itemBody.type == 'p' && itemBody.text)?_c('p',{key:indBofy,staticClass:"mb-4"},[_vm._v("\n                  "+_vm._s(itemBody.text)+"\n                ")]):_vm._e(),_vm._v(" "),(
                    itemBody.type &&
                      itemBody.type == 'button' &&
                      itemBody.text &&
                      itemBody.url)?_c('a',{key:indBofy,staticClass:"rnaButton button",attrs:{"title":(item.title && item.title.text) || itemBody.text,"href":itemBody.url},on:{"click":function($event){_vm.ga_event('click', (item.title && item.title.text) || itemBody.text)}}},[_vm._v("\n                  "+_vm._s(itemBody.text)+"\n                ")]):_vm._e()]})],2)])])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }