//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleArticle',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  components: {
    ArticleCard: () => import('~/components/ArticleCard.vue'),
    ArticleCardSlim: () => import('~/components/ArticleCardSlim.vue'),
    ArticleCardNumbers: () => import('~/components/ArticleCardNumbers.vue'),
    ArticleCardPhoto: () => import('~/components/ArticleCardPhoto.vue'),
    ReviewCard: () => import('~/components/ReviewCard.vue')
  },
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    content: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    zone: {
      type: String,
      default: ''
    }
  }
}
