var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isAMP)?_c('div',{staticClass:"MdlSknBnn padding-x2-y3"},[(_vm.$isAMP)?_c('amp-ad',{staticClass:"d-block margin-auto",attrs:{"width":320,"height":50,"type":"doubleclick","data-slot":("/" + (_vm.$config.googleads) + "/" + (_vm.data.ad_unit)),"layout":"fixed"}}):_vm._e()],1):_c('div',{staticClass:"MdlSknBnn"},[_c('div',{staticClass:"skin-banner"},[_c('module-gpt',{attrs:{"data":{
        ad_unit: ("" + (_vm.data.ad_unit)),
        out_of_page: false,
        sizes: '1260x100',
        sizesMapping: [
          [[1290, 0], [1260, 100]],
          [[960, 0], [930, 85]],
          [[798, 0], [768, 75]],
          [[606, 0], [576, 65]],
          [[0, 0], [320, 50]]
        ]
      }}})],1),_vm._v(" "),_c('div',{staticClass:"skin-right"},[_c('module-gpt',{attrs:{"data":{
        ad_unit: ((_vm.data.ad_unit) + "_Right"),
        out_of_page: false,
        sizes: '330x950',
        sizesMapping: [
          [[1290, 0], [330, 950]],
          [[0, 0], false]
        ]
      }}})],1),_vm._v(" "),_c('div',{staticClass:"skin-left"},[_c('module-gpt',{attrs:{"data":{
        ad_unit: ((_vm.data.ad_unit) + "_Left"),
        out_of_page: false,
        sizes: '330x950',
        sizesMapping: [
          [[1290, 0], [330, 950]],
          [[0, 0], false]
        ]
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }