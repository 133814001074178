//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)
export default {
  props: {
    video: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      logo: { src: require('@/assets/img/logo-com.svg'), alt: 'Runnea.com', width: 95, height: 13 }
    }
  },
  methods: {
    getIdFromUrl (url) {
      return this.$youtube.getIdFromUrl(url)
    }
  }
}
