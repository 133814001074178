//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'

export default {
  mixins: [global],
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    content: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    let article = false
    if (this.content.info && (this.content.info.length > 0)) {
      article = this.content.info[0]
    } else if (this.data && (this.data.length > 0)) {
      article = this.data[0]
    }
    return {
      projectData,
      article
    }
  }
}
