//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    shortquest: {
      type: String,
      default: () => ''
    }
  }
}
