//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'
Vue.config.silent = true
export default {
  components: {
    VueTypeaheadBootstrap
  },
  mixins: [global],
  inheritAttrs: false,
  props: {
    product: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    },
    products: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  mounted () {
    if (!this.$store.state.productsLoaded) {
      this.$store.dispatch('loadProducts')
    }
  },
  methods: {
    search () {
      if (this.$refs.browser.inputValue !== '') {
        window.location.href = `${projectData.contents.product_types.products.paths.home}/${projectData.contents.product_types.products.paths.products}/${projectData.contents.product_types.products.paths.products_all}/?q=${this.$refs.browser.inputValue}`
      }
    },
    findItem (product) {
      const pathMatches = product.path.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
      const prodTypeConfig = Object.values(projectData.contents.product_types).filter(p => p.id === product.type_id)
      const url = `/${prodTypeConfig[0].paths.home}${pathMatches[2]}`
      this.$router.push(url)
    }
  }
}
