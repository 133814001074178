//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    locales () {
      return this.$i18n.locales.filter(l => l.code !== this.$i18n.locale)
    }
  }
}
