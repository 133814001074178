//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModuleRAButton',
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      close: false
    }
  }
}
