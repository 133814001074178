//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModulePrices',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  components: {
    PriceCard: () => import('~/components/PriceCard.vue'),
    AdvertisingProductsCard: () => import('~/components/AdvertisingProductsCard.vue')
  },
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    zone: {
      type: String,
      default: ''
    },
    org: {
      type: [Number, String],
      default: () => 0
    }
  },
  computed: {
    // footer () {
    //   return this.generic.footer.filter(f => f.url)
    // }
  }
}
