//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleReview',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  components: {
    ReviewCard: () => import('~/components/ReviewCard.vue')
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    }
  }
}
