//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      user: {
        _p: `${this.$config.id}`,
        email: '',
        validEmail: true,
        errorEmail: '',
        validPrivacyCheck: true,
        privacyCheck: 'not_accepted'
      },
      modal: {
        show: false,
        title: this.$t('NewsLettersCard.thanks'),
        message: ''
      }
    }
  },
  methods: {
    isValidEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.user.errorEmail = ''
      if ((this.user.email !== '') && (!re.test(this.user.email))) {
        this.user.validEmail = false
        this.user.errorEmail = 'Formato de email incorrecto'
      } else {
        this.user.validEmail = true
      }
    },
    async submitData () {
      if (this.user.email !== '') {
        const bodyFormData = new FormData()
        bodyFormData.append('_p', this.user._p)
        bodyFormData.append('email', this.user.email)
        bodyFormData.append('service', 'newsletter')
        try {
          await axios.post(this.$config.api + '/app/v1/users/services', bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'No Auth'
            }
          }).then((resp) => {
            if (resp.data.code === 200) {
              this.modal.message = this.$t('NewsLettersCard.successful')
              this.modal.show = true
            } else {
              this.modal.title = 'Oops'
              this.modal.message = this.$t('NewsLettersCard.error')
              this.modal.show = true
            }
          })
        } catch (error) {
          this.modal.title = 'Oops'
          this.modal.message = this.$t('NewsLettersCard.error_try')
          this.modal.show = true
        }
      } else if (this.user.email === '') {
        this.user.validEmail = false
        this.user.errorEmail = this.$t('NewsLettersCard.required')
      }
    }
  }
}
