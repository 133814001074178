//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  components: {
    AdvertisingProductsCard: () => import('~/components/AdvertisingProductsCard.vue'),
    PriceCard2: () => import('~/components/PriceCardPrice.vue')
  },
  mixins: [global],
  props: {
    price: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    org: {
      type: [Number, String],
      default: () => 0
    },
    pos: {
      type: [Number, String],
      default: () => 0
    },
    at: {
      type: [Number, String],
      default: () => 0
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    w: {
      type: Number,
      default: 0
    },
    country: {
      type: Object,
      default: () => null
    }
  }
}
