var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.content.info && (_vm.content.info.length > 0))?_c('div',{staticClass:"MgzMdlVid"},[(_vm.generic.header)?_c('MagazineHeader',{attrs:{"data":_vm.generic.header}}):_vm._e(),_vm._v(" "),_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"videoList d-flex flex-nowrap flex-md-column flex-gap-3"},[_vm._l((3),function(iVideo){return [(_vm.content.info[iVideo])?_c('div',{key:iVideo,staticClass:"videoItem"},[_c('div',{staticClass:"video"},[_c('youtube',{attrs:{"width":"640","height":"360","video-id":_vm.getIdFromUrl(_vm.content.info[iVideo].url),"title":_vm.content.info[iVideo].title,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})],1),_vm._v(" "),_c('div',{staticClass:"content d-flex flex-column flex-gap-2"},[_c('div',{staticClass:"d-flex flex-row flex-gap-2"},[_c('a',{attrs:{"href":_vm.content.info[iVideo].channel,"target":"_blank","rel":"noopener noreferrer"}},[_c('span',[_vm._v("Youtube")])]),_vm._v(" "),(_vm.content.info[iVideo].time)?[_vm._v("\n                    —\n                    "),_c('span',[_vm._v(_vm._s(_vm.formatDate(parseInt(_vm.content.info[iVideo].time * 1000), {
                      year: 'numeric',
                      day: 'numeric',
                      month: 'short'
                    })))])]:_vm._e()],2),_vm._v(" "),_c('h3',{staticClass:"title m-0"},[_c('a',{attrs:{"href":_vm.content.info[iVideo].url,"title":_vm.content.info[iVideo].title,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("\n                    "+_vm._s(_vm.content.info[iVideo].title)+"\n                  ")])])])]):_vm._e()]}),_vm._v(" "),(_vm.footer && _vm.footer.text && _vm.footer.url)?_c('div',[_c('app-link',{staticClass:"moreNews d-flex flex-row flex-gap-2 align-items-center",attrs:{"url":_vm.footer.url}},[_vm._v("\n              "+_vm._s(_vm.footer.text)+"\n              "),_c('img',{attrs:{"src":require("assets/img/icons/icon-arrow-right.svg"),"width":"16","height":"16"}})])],1):_vm._e()],2)]),_vm._v(" "),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"singleVideo d-none d-md-block"},[_c('div',{staticClass:"videoItem"},[_c('div',{staticClass:"video"},[_c('youtube',{attrs:{"width":"640","height":"360","video-id":_vm.getIdFromUrl(_vm.content.info[0].url),"title":_vm.content.info[0].title,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})],1),_vm._v(" "),_c('div',{staticClass:"content d-flex flex-column flex-gap-2"},[_c('div',{staticClass:"d-flex flex-row flex-gap-2"},[_c('a',{attrs:{"href":_vm.content.info[0].channel,"target":"_blank","rel":"noopener noreferrer"}},[_c('span',[_vm._v("Youtube")])]),_vm._v(" "),(_vm.content.info[0].time)?[_vm._v("\n                  —\n                  "),_c('span',[_vm._v(_vm._s(_vm.formatDate(parseInt(_vm.content.info[0].time * 1000), {
                    year: 'numeric',
                    day: 'numeric',
                    month: 'short'
                  })))])]:_vm._e()],2),_vm._v(" "),_c('app-link',[_c('h3',{staticClass:"title m-0"},[_vm._v("\n                  "+_vm._s(_vm.content.info[0].title)+"\n                ")])])],1)])])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }