var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MdlPrcBrg my-4"},[_c('ModuleHeader',{attrs:{"generic":_vm.generic}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.prices),function(price,ind){return _c('div',{key:ind,staticClass:"price-ranking-item border-bottom"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row mb-2"},[(price.clicks)?_c('div',{staticClass:"d-flex align-items-center gap-2 pr-badge mr-2 onfire text-small weight-semibold"},[_c('img',{staticClass:"img-width mr-2",attrs:{"height":"16","width":"16","alt":"Onfire","src":require("assets/img/icons/icon-onfire.svg")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(price.clicks == 1 ? 'ModulePricesBargain.click' : 'ModulePricesBargain.clicks', {
              clicks: price.clicks
            })))])]):_vm._e(),_vm._v(" "),(price.updated && (parseInt(price.updated) >= (_vm.now - (24 * 3600))))?_c('div',{staticClass:"new pr-badge align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('ModulePricesBargain.new')))])]):_vm._e(),_vm._v(" "),(price.updated)?_c('div',{staticClass:"d-flex align-items-center ml-auto gap-2"},[_c('img',{staticClass:"img-width mr-2",attrs:{"height":"16","width":"16","alt":"Date","src":"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDQuNjAxODFMOC41IDExLjYwMThMNC41IDcuMTAxODFMMSAxMC42MDE4IiBzdHJva2U9IiM3RjgwODMiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMTAgNC42MDE4MUgxNVY5LjYwMTgxIiBzdHJva2U9IiM3RjgwODMiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8L3N2Zz4K"}}),_vm._v(" "),_c('span',{staticClass:"date color-grey text-small"},[_vm._v(_vm._s(_vm.getTimeAgo(price.updated)))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row align-items-center product-container mb-2 gap-2"},[_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('app-link',{staticClass:"pointer",attrs:{"track":price.link && price.link.track ? {
                id: price.store.id,
                code: price.link.track,
                pos: ind + 1,
                org: 'Chollos',
              } : null,"url":price.link && price.link.url,"target":price.link && price.link.target ? price.link.target : '_blank',"nofollow":price.link && (price.link.nofollow === '1'),"obfuscated":true,"ga":{
                action: 'price',
                category: 'Chollos',
                label: price.name
              }}},[(price.photo)?_c('img',{staticClass:"pr-image mb-2",attrs:{"width":price.photo.width ? price.photo.width : 50,"height":price.photo.height ? price.photo.height : 50,"src":price.photo.src || price.photo}}):_vm._e()]),_vm._v(" "),_c('app-link',{staticClass:"text-center pointer",attrs:{"track":price.link && price.link.track ? {
                id: price.store.id,
                code: price.link.track,
                pos: ind + 1,
                org: 'Chollos',
              } : null,"url":price.link && price.link.url,"target":price.link && price.link.target ? price.link.target : '_blank',"nofollow":price.link && (price.link.nofollow === '1'),"obfuscated":true,"ga":{
                action: 'price',
                category: 'Chollos',
                label: price.name
              }}},[(price.store.logo)?_c('img',{staticClass:"store-logo",attrs:{"width":price.store.logo.width ? price.store.logo.width : 250,"height":price.store.logo.height ? price.store.logo.height : 50,"src":price.store.logo.src}}):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[(price.name)?_c('span',{staticClass:"name weight-bold"},[_c('app-link',{staticClass:"pointer",attrs:{"track":price.link && price.link.track ? {
                  id: price.store.id,
                  code: price.link.track,
                  pos: ind + 1,
                  org: 'Chollos',
                } : null,"url":price.link && price.link.url,"target":price.link && price.link.target ? price.link.target : '_blank',"nofollow":price.link && (price.link.nofollow === '1'),"obfuscated":true,"ga":{
                  action: 'price',
                  category: 'Chollos',
                  label: price.name
                }}},[_vm._v(_vm._s(price.name)+"\n              ")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row align-items-center gap-3 price-container"},[(price.pvp)?_c('span',{staticClass:"before color-grey text-small"},[_vm._v(_vm._s(_vm.formatPrice(price.pvp)))]):_vm._e(),_vm._v(" "),(price.finalPrice)?_c('span',{staticClass:"price color-primary weight-bold"},[_vm._v(_vm._s(_vm.formatPrice(price.finalPrice)))]):_vm._e(),_vm._v(" "),(price.discount)?_c('span',{staticClass:"discount text-medium color-primary"},[_vm._v("-"+_vm._s(price.discount)+"% "+_vm._s(_vm.$t('ModulePricesBargain.discount')))]):_vm._e(),_vm._v(" "),(price.label)?_c('span',{staticClass:"discount text-small"},[_vm._v(_vm._s(price.label))]):_vm._e()]),_vm._v(" "),(price.discount_price && price.discount_price.text)?_c('div',{staticClass:"d-flex flex-row align-items-center gap-3 price-container"},[_c('span',{staticClass:"discount text-medium color-primary"},[_vm._v(_vm._s(price.discount_price.text))])]):_vm._e(),_vm._v(" "),(price.sizes && price.sizes.length)?_c('hr',{staticClass:"pr-divider"}):_vm._e(),_vm._v(" "),(price.sizes && price.sizes.length)?_c('div',{staticClass:"d-flex flex-row flex-wrap size-list align-items-center gap-2"},[_c('span',{staticClass:"text-small"},[_vm._v("\n                "+_vm._s(_vm.$t('list.filters.size'))+":\n              ")]),_vm._v(" "),_vm._l((price.sizes),function(size){return _c('span',{key:size,staticClass:"size text-small"},[_vm._v(_vm._s(size))])})],2):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column flex-md-row gap-3 justify-content-md-end"},[_c('app-link',{staticClass:"btn btn-primary align-self-md-end order-md-2",attrs:{"track":price.link && price.link.track ? {
              id: price.store.id,
              code: price.link.track,
              pos: ind + 1,
              org: 'Chollos',
            } : null,"url":price.link && price.link.url,"target":price.link && price.link.target ? price.link.target : '_blank',"nofollow":price.link && (price.link.nofollow === '1'),"obfuscated":true,"ga":{
              action: 'price',
              category: 'Chollos',
              label: price.name
            }}},[_vm._v("\n            "+_vm._s((price.link && price.link.title) || _vm.$t('ModulePricesBargain.go'))+"\n          ")])],1)])])}),0),_vm._v(" "),_c('ModuleFooter',{attrs:{"generic":_vm.generic}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }