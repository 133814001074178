//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModuleRAProducts',
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      close: false
    }
  },
  methods: {
    highlightingText (text) {
      return text.replace(/\*([^*]+)\*/g, function (text, highlight) {
        return '<u class="highlight">' + highlight + '</u>'
      })
    }
  }
}
