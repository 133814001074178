//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import * as murmurhash from 'murmurhash'
export default {
  name: 'ModulePricesBargain',
  ...(process.env.cache && { serverCacheKey: props => murmurhash.v2(JSON.stringify(props)) }),
  mixins: [global],
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    zone: {
      type: String,
      default: ''
    },
    featuredPrices: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    at: {
      type: [Number, String],
      default: () => 0
    },
    org: {
      type: [Number, String],
      default: () => 0
    }
  },
  data () {
    return {
      prices: [],
      now: Math.floor(Date.now() / 1000)
    }
  },
  computed: {
    footer () {
      return this.generic.footer.filter(f => f.url)
    }
  },
  created () {
    this.prices = [...this.data]
    this.prices.forEach(price => {
      price.type_id = 4
      price.finalPrice = price.price ? this.setPrice(price) : 0
      price.discount = price.finalPrice ? this.setDiscount(price.finalPrice, price.pvp) : 0
      const sizes = price.features ? price.features.find(f => f.id === 'size') : null
      price.sizes = sizes?.values || []
    })
    this.prices = this.prices.filter(price => price.finalPrice < price.pvp)

    this.prices.sort((a, b) => {
      return b.clicks - a.clicks
    })
  },
  methods: {
    setPrice (price) {
      return (price.discount_price && price.discount_price.price) || price.price
    },
    setDiscount (price, pvp) {
      return pvp ? 100 - Math.round((price / pvp) * 100) : 0
    },
    setLikesYes (likes, discount) {
      return likes + Math.round(discount / 10)
    },
    async insertLike (content, type, price, like, index) {
      const bodyFormData = new FormData()
      bodyFormData.append('_p', this.$config.id)
      bodyFormData.append('price', price)
      const response = await this.$axios.post(`${this.$config.api}/app/v1/prices/likes/${content}/${type}/${like}`, bodyFormData, { withCredentials: true })
      if (response.data) {
        this.prices[index].likes = response.data.likes
      }
    }
  }
}
