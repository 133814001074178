//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleProductFeatured',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  components: {
    ProductCard: () => import('~/components/ProductCard.vue'),
    ProductCardPrincipal: () => import('~/components/ProductCardPrincipal.vue'),
    ProductCardSecondary: () => import('~/components/ProductCardSecondary.vue'),
    ProductCardStandard: () => import('~/components/ProductCardStandard.vue'),
    ReviewList: () => import('~/components/ReviewList.vue')
  },
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    zone: {
      type: String,
      default: ''
    },
    featuredPrices: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    at: {
      type: [Number, String],
      default: () => 0
    },
    org: {
      type: [Number, String],
      default: () => 0
    },
    preload: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    footer () {
      return this.generic.footer.filter(f => f.url)
    }
  }
}
