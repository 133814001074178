//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    logout () {
      this.$auth.logout()
    }
  }
}
