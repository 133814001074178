//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleProductLeaf',
  ...(process.env.cache && { serverCacheKey: props => murmurhash.v2(JSON.stringify(props)) }),
  components: {
    PriceCard: () => import('~/components/PriceCard.vue'),
    AdvertisingProductsCard: () => import('~/components/AdvertisingProductsCard.vue')
  },
  props: {
    featuredPrices: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    at: {
      type: [Number, String],
      default: () => 0
    },
    org: {
      type: [Number, String],
      default: () => 0
    },
    country: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    footer () {
      return this.generic.footer.filter(f => f.url)
    }
  }
}
