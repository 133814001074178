var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isAMP)?_c('div',{staticClass:"MdlSkn"},[(_vm.$isAMP)?_c('amp-ad',{attrs:{"width":320,"height":50,"type":"doubleclick","data-slot":("/" + (_vm.$config.googleads) + "/" + (_vm.data.ad_unit) + "_XS"),"layout":"fixed"}}):_vm._e()],1):_c('div',{staticClass:"MdlSkn"},[_c('div',{staticClass:"skin-banner-xl"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('module-gpt',{staticClass:"m-0",attrs:{"data":{
          ad_unit: ((_vm.data.ad_unit) + "_XL"),
          out_of_page: false,
          sizes: '1260x100'
        }}})],1)],1),_vm._v(" "),_c('div',{staticClass:"skin-banner-lg my-2"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('module-gpt',{staticClass:"m-0",attrs:{"data":{
          ad_unit: ((_vm.data.ad_unit) + "_LG"),
          out_of_page: false,
          sizes: '930x85,768x75,576x65,320x50'
        }}})],1)],1),_vm._v(" "),_c('div',{staticClass:"skin-banner-md my-2"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('module-gpt',{staticClass:"m-0",attrs:{"data":{
          ad_unit: ((_vm.data.ad_unit) + "_MD"),
          out_of_page: false,
          sizes: '768x75,576x65,320x50'
        }}})],1)],1),_vm._v(" "),_c('div',{staticClass:"skin-banner-sm my-2"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('module-gpt',{staticClass:"m-0",attrs:{"data":{
          ad_unit: ((_vm.data.ad_unit) + "_SM"),
          out_of_page: false,
          sizes: '576x65,320x50'
        }}})],1)],1),_vm._v(" "),_c('div',{staticClass:"skin-banner-xs my-2"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('module-gpt',{staticClass:"m-0",attrs:{"data":{
          ad_unit: ((_vm.data.ad_unit) + "_XS"),
          out_of_page: false,
          sizes: '320x50'
        }}})],1)],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('div',{staticClass:"skin-right"},[_c('module-gpt',{staticClass:"m-0",attrs:{"data":{
          ad_unit: ((_vm.data.ad_unit) + "_Right"),
          out_of_page: false,
          sizes: '330x950'
        }}})],1)]),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('div',{staticClass:"skin-left"},[_c('module-gpt',{staticClass:"m-0",attrs:{"data":{
          ad_unit: ((_vm.data.ad_unit) + "_Left"),
          out_of_page: false,
          sizes: '330x950'
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }