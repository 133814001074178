//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import Vue from 'vue'
import global from '~/mixins/global.js'
Vue.config.silent = true
export default {
  components: {
    VueTypeaheadBootstrap
  },
  mixins: [global],
  data () {
    return {
      brands: []
    }
  },
  methods: {
    findItem (brand) {
      let url = this.$router.history.current.fullPath
      const sustituir = url.split('/')[3]
      url = url.replace(sustituir, brand.name.toLowerCase())
      this.$router.push(url)
    },
    loadBrands () {
      this.$store.dispatch('loadBrands').then(() => {
        this.brands = this.$store.state.brands
      })
    }
  }
}
