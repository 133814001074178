//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import Vue from 'vue'
import projectData from '~/static/config/project.json'
import global from '~/mixins/global.js'
Vue.config.silent = true
export default {
  components: {
    /* VueTypeaheadBootstrap */
  },
  mixins: [global],
  data () {
    return {
      articles: [],
      searching: false
    }
  },
  methods: {
    findItem (article) {
      let url = ''
      let pathMatches = article.path.match(/^\/json\/articles\/(.+?)index.json$/)
      if (pathMatches) {
        url = `/${projectData.contents.articles.all.paths.home}/${pathMatches[1]}`
      } else {
        pathMatches = article.path.match(/^\/json\/galleries\/(.+?).json$/)
        url = `/${projectData.contents.galleries.all.paths.home}/${pathMatches[1]}.html`
      }
      this.$router.push(url)
    },
    loadArticles () {
      if (!this.$store.state.articlesLoaded) {
        this.$store.dispatch('loadArticles').then(() => {
          this.articles = this.$store.state.articles
        })
      } else {
        this.articles = this.$store.state.articles
      }
    }
  }
}
