//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      show: 'no'
    }
  },
  mounted () {
    /* setTimeout(() => {
      this.show = ((sessionStorage.getItem('InqMdlShow') === 'yes') || (sessionStorage.getItem('InqMdlShow') === null)) && 'yes'
    }, 5000) */
  },
  methods: {
    hide () {
      this.show = 'no'
      sessionStorage.setItem('InqMdlShow', 'no')
    }
  }
}
