//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModuleGptAd',
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  mounted () {
    const googletag = window.googletag || {}
    googletag.cmd = googletag.cmd || []
    if (googletag) {
      if (this.data.footer === '1') {
        googletag.cmd.push(() => {
          const anchorSlot = googletag.defineOutOfPageSlot(`/${this.$config.googleads}/${this.data.ad_unit}`, googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR)
          googletag.pubads().enableSingleRequest()
          googletag.enableServices()
          if (anchorSlot) {
            anchorSlot.addService(googletag.pubads())
            googletag.display(anchorSlot)
          }
        })
      }
    }
  }
}
