//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModuleRAStories',
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  methods: {
    ga_event (action, description) {
      this.$gtag('event', action, {
        event_category: 'ModuleRAStories',
        event_label: description
      })
    }
  }
}
