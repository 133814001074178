//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleProductRanking',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  mixins: [global],
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    zone: {
      type: String,
      default: ''
    },
    featuredPrices: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    at: {
      type: [Number, String],
      default: () => 0
    },
    org: {
      type: [Number, String],
      default: () => 0
    }
  },
  data () {
    return {
      products: []
    }
  },
  computed: {
    footer () {
      return this.generic.footer.filter(f => f.url)
    }
  },
  created () {
    this.products = this.data.filter(product => product.score)
  }
}
