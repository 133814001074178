//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
import global from '~/mixins/global.js'
export default {
  name: 'ModuleForm',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  mixins: [global],
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      field: '',
      form: [],
      images: [],
      modal: {
        show: false,
        title: 'Contacto',
        message: '',
        button: ''
      }
    }
  },
  methods: {
    async saveInfo () {
      this.valid = true
      this.field = ''
      this.form = []
      let files = 0
      const bodyFormData = new FormData()
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index]
        const formElement = this.$refs[element.name]
        let value = ''
        if (formElement) {
          value = ((formElement[0].type === 'checkbox') ? formElement[0].checked : formElement[0].value)
          if ((element.required === '1' && (value !== '' && value)) || element.required !== '1' || element.type === 'hidden') {
            if (formElement[0].type === 'file') {
              bodyFormData.append('file[]', this.images[files])
              files++
            } else {
              this.form.push({ name: element.name, value })
            }
          } else {
            this.valid = false
            this.field = element.name
            break
          }
        } else {
          value = element.name
          bodyFormData.append('tipo', value)
        }
      }
      if (this.valid) {
        bodyFormData.append('_p', this.$config.id)
        bodyFormData.append('id', parseInt(this.generic.id))
        bodyFormData.append('info', JSON.stringify(this.form))
        try {
          await this.$axios.post(this.$config.api + '/app/v1/forms/formInsert', bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'No Auth'
            }
          }).then((resp) => {
            if (resp.status === 200) {
              this.modal.message = this.generic.textoMod || this.$t('ModuleForm.query_received')
              this.modal.button = this.generic.botonMod || this.$t('ModuleForm.confirmButton')
              this.modal.title = this.generic.tituloMod || this.$t('ModuleForm.title')
              this.modal.show = true
              if (this.generic.redirection && this.generic.redirection !== '') {
                if (this.setLinkType(this.generic.redirection) === 'EXTERNO') {
                  window.open(this.generic.redirection)
                } else {
                  window.location.href = this.generic.redirection
                }
              }
            }
          })
        } catch (error) {
          this.valid = false
          this.field = this.$t('ModuleForm.error_submitting')
        }
      }
    },
    closeModal () {
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index]
        const formElement = this.$refs[element.name]
        if (formElement) {
          if (formElement[0].type === 'checkbox') {
            formElement[0].checked = false
          } else {
            formElement[0].value = ''
          }
        }
      }
      window.location.href = '#header'
    },
    handleImage (e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        this.images.push(e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }
}
