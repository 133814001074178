//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleSlider',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  components: {
    SliderCardProduct: () => import('~/components/SliderCardProduct.vue'),
    SliderCardPromocion: () => import('~/components/SliderCardPromocion.vue'),
    SliderCardPromocionTemporal: () => import('~/components/SliderCardPromocion.vue'),
    SliderCardArticle: () => import('~/components/SliderCardArticle.vue'),
    SliderCardFinder: () => import('~/components/SliderCardFinder.vue')
  },
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    zone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  }
}
