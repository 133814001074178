//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    footer: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: ''
    }
  }
}
