//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
export default {
  data () {
    return {
      isOpen: false,
      projectData
    }
  },
  mounted () {
    if (!this.getGDPR() === true) {
      setTimeout(() => {
        this.isOpen = true
      }, 10000)
    }
  },
  methods: {
    getGDPR () {
      if (process.browser) {
        return this.$cookiz.get(projectData.contents.politics.all.paths.cookies)
      }
    },
    accept () {
      if (process.browser) {
        this.isOpen = false
        this.$cookiz.set(projectData.contents.politics.all.paths.cookies, true, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365
        })
      }
    },
    deny () {
      if (process.browser) {
        this.isOpen = false
        this.$cookiz.set(projectData.contents.politics.all.paths.cookies, false, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365
        })
      }
    }
  }
}
