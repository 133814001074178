//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import validations from 'validation-utils'
import axios from 'axios'
export default {
  props: {
    buttonText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      reset: false,
      userInfo: {
        _p: `${this.$config.id}`,
        email: '',
        message: ''
      }
    }
  },
  methods: {
    async sendPassword () {
      const bodyFormData = new FormData()
      bodyFormData.append('_p', this.userInfo._p)
      bodyFormData.append('email', this.userInfo.email)
      try {
        await axios.post(this.$config.api + '/app/v1/users/password', bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'No Auth'
          }
        }).then((resp) => {
          // console.log(resp)
          this.userInfo.message = this.$t('register.email_sent') + ' ' + this.userInfo.email + this.$t('register.check_inbox')
          this.reset = true
        })
      } catch (error) {
        this.reset = true
        this.userInfo.message = this.$t('register.not_exist_email')
      }
    }
  }
}
