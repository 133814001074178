//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModuleRATextRotate',
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  mounted () {
    this.TxtRotate(this.$refs.rotate, this.data.list, 2000)
  },
  methods: {
    TxtRotate (el, toRotate, period) {
      this.tick = function () {
        const i = this.loopNum % this.toRotate.length
        const fullTxt = this.toRotate[i]

        if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1)
        } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1)
        }

        this.el.textContent = this.txt

        const that = this
        let delta = 150 - Math.random() * 100

        if (this.isDeleting) { delta /= 2 }

        if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period
          this.isDeleting = true
        } else if (this.isDeleting && this.txt === '') {
          this.isDeleting = false
          this.loopNum++
          delta = 500
        }

        setTimeout(function () {
          that.tick()
        }, delta)
      }

      this.toRotate = toRotate
      this.el = el
      this.loopNum = 0
      this.period = parseInt(period, 10) || 2000
      this.txt = ''
      this.tick()
      this.isDeleting = false
    }
  }
}
