//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import Vue from 'vue'
import projectData from '~/static/config/project.json'
import global from '~/mixins/global.js'
Vue.config.silent = true
export default {
  components: {
    VueTypeaheadBootstrap
  },
  mixins: [global],
  data () {
    return {
      products: []
    }
  },
  methods: {
    findItem (product) {
      const pathMatches = product.path.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
      const prodTypeConfig = Object.values(projectData.contents.product_types).filter(p => p.id === product.type_id)
      const url = `/${prodTypeConfig[0].paths.home}${pathMatches[2]}`
      this.$router.push(url)
    },
    loadProducts () {
      if (!this.$store.state.productsLoaded) {
        this.$store.dispatch('loadProducts').then(() => {
          this.products = this.$store.state.products
        })
      } else {
        this.products = this.$store.state.products
      }
    }
  }
}
