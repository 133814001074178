//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
export default {
  name: 'ModuleProductFeaturedComparison',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      versus: { src: require('@/assets/img/icons/icon-versus.svg'), alt: 'Versus icon' }
    }
  },
  computed: {
    footer () {
      return this.generic.footer ? this.generic.footer.filter(f => f.url) : []
    }
  }
}
