//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import global from '~/mixins/global.js'
import project from '~/static/config/project.json'

export default {
  mixins: [global],
  inheritAttrs: false,
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    carrousel: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const priceButtons = {
      man: false,
      woman: false
    }

    const genderFeature = this.product.features.find(feature => (feature.id === 'gender'))
    const productType = Object.values(project.contents.product_types).find(type => type.id === this.product.type_id)
    if (productType) {
      productType.filters = productType.filters.map((filter) => {
        if (filter.share && project.filters_share && project.filters_share[filter.share]) {
          filter = { ...project.filters_share[filter.share], ...filter }
        }
        return filter
      })
      const featureGender = productType.filters.find(filter => (filter.id === 'gender'))

      if (genderFeature && featureGender) {
        const featureGenderMan = featureGender.values.find(value => (value.id === 'man'))
        const featureGenderWoman = featureGender.values.find(value => (value.id === 'woman'))
        if (featureGenderMan && genderFeature.values.includes('man')) {
          priceButtons.man = {
            seo: featureGenderMan.seo || featureGenderMan.name,
            path: `${featureGender.url || featureGender.name}-${featureGenderMan.url || featureGenderMan.name}`
          }
        }
        if (featureGenderWoman && genderFeature.values.includes('woman')) {
          priceButtons.woman = {
            seo: featureGenderWoman.seo || featureGenderWoman.name,
            path: `${featureGender.url || featureGender.name}-${featureGenderWoman.url || featureGenderWoman.name}`
          }
        }
      }
    }

    return { priceButtons }
  }
}
