//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

export default {
  mixins: [global],
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    content: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    let footer = false
    if (this.generic.footer && (this.generic.footer.length > 0)) {
      footer = this.generic.footer[0]
    }
    return {
      footer
    }
  },
  methods: {
    getIdFromUrl (url) {
      return this.$youtube.getIdFromUrl(url)
    }
  }
}
